import dynamic from 'next/dynamic'
import Script from 'next/script'
import { useEffect, useState } from 'react'

const CoveoScripts = ({ isPLPSRP = false }) => {
  const [loadCoveoAtomicScripts, setLoadCoveoAtomicScripts] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadCoveoAtomicScripts(true)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <>
      <Script src='/coveo/google-analytics/google-analytics-four.js' type='text/javascript' />
      <Script src='/coveo/google-analytics/google-analytics.js' type='text/javascript' />
      <Script src='/coveo/coveo-script.js?v=dd111848-a2c3-48d7-9fc0-c665bd9852cc' type='module' />
      <Script src='/coveo/coveo-events.js?v=dd111848-a2c3-48d7-9fc0-c665bd9852cc' type='module' />
      <Script src='/coveo/coveo-events-search.js?v=dd111848-a2c3-48d7-9fc0-c665bd9852cc' type='module' />
      {isPLPSRP && <Script src='/coveo/coveo-events-list.js?v=dd111848-a2c3-48d7-9fc0-c665bd9852cc' type='module' />}
      {loadCoveoAtomicScripts && <Script src='/coveo/atomic/atomic.esm.js?v=dd111848-a2c3-48d7-9fc0-c665bd9852cc' type='module' />}
      {loadCoveoAtomicScripts && <Script src='/wolseleysearchpage/wolseleysearchpage.esm.js?v=dd111848-a2c3-48d7-9fc0-c665bd9852cc' type='module' />}
    </>
  )
}

export default dynamic(() => Promise.resolve(CoveoScripts), {
  ssr: false,
})
